/**
 * @author: Pankaj Kulshreshtha | Cheers Interactive
 * @date : 15/Mar/2021
 * File Description: Survey Form Designer
 */
import React, { useEffect, useState } from "react";
import * as SurveyCreator from "survey-creator";
import * as Survey from "survey-knockout";
import * as widgets from "surveyjs-widgets";
import "survey-creator/survey-creator.css";
import "../css/survey.css";
import "nouislider/distribute/nouislider.css";
import "jquery-bar-rating/dist/themes/css-stars.css";
import "jquery-bar-rating/dist/themes/fontawesome-stars.css";
import $ from "jquery";
import "jquery-bar-rating";
import "jquery-ui-dist/jquery-ui.css";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { showAlertBox } from "../../../../../middleware/actions/alertBoxAction";
import { setSideNavForcedActiveLink } from "../../../../../middleware/actions/sideNavAction";
import { setBreadCrumb } from "../../../../../middleware/actions/breadCrumbAction";
import ActionButtons from "../actionButtons";
import { fetchSingleSurvey, fetchSurveys, updateSurvey } from "../../../../../middleware/services/surveyApi";
import { actionError, actionSuccess, showLoader, hideLoader } from "../../../../../middleware/actions/utilityAction";
import { getLoggedInUser, getProductVariant } from "../../../../../utilities";
import { setDesign } from "../../../../../middleware/actions/surveyAction";
import SURVEY_CONSTANT from "../../constants";
import { PRODUCT_VARIANT } from "../../../../../constants";
import AccessDenied from "../../../../components/accessDenied";

// initialize CKEditor
const CKEDITOR = window.CKEDITOR;
// theme colors
const mainColor = "#000000";
const mainHoverColor = "#F4505F"; // black
const textColor = "#000000";
const headerColor = "#343a40";
const headerBackgroundColor = "#4a4a4a";
const bodyContainerBackgroundColor = "#f8f8f8"; //gray
let showdown = require("showdown");
widgets.jquerybarrating(Survey, $);
widgets.jqueryuidatepicker(Survey, $);
widgets.nouislider(Survey);
widgets.sortablejs(Survey);
widgets.prettycheckbox(Survey);
widgets.inputmask(Survey);
widgets.jqueryuidatepicker(Survey, $);
widgets.select2(Survey, $);
widgets.select2tagbox(Survey, $);
require("jquery-ui-dist/jquery-ui.js");

const DesignSurvey = (props) => {
  const [designData, setDesignData] = useState(null);
  const surveyCommonState = useSelector((state) => state.surveyState);
  const dispatch = useDispatch();
  const loggedInUser = getLoggedInUser();
  const productVariant = getProductVariant();
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [haveUnsavedChanges, setHaveUnsavedChanges] = useState(false);
  const [surveyDesignData, setSurveyDesignData] = useState(surveyCommonState.designDetails);
  const surveyId = props.surveyId;
  const breadCrumbLinks = [
    { linkUrl: "/survey", linkName: "Survey", linkActive: false },
    {
      linkUrl: surveyId ? `/survey/${surveyId}/basic-details#design` : "/survey/basic-details#design",
      linkName: (designData && designData.surveySerialNo) || "",
      linkActive: false,
    },
    {
      linkUrl: `/survey/${surveyId}/edit${Object.keys(props.hashArr)[props.selectedIndex]}`,
      linkName: Object.values(props.hashArr)[props.selectedIndex],
      linkActive: true,
    },
  ];

  const getFormJsonById = async () => {
    let formJsonData = {};
    let params = {};
    let defaultFilter = [];
    setHaveUnsavedChanges(false);
    if (productVariant) defaultFilter.push(["productVariant.productName", "eq", productVariant]);
    params = {
      filters: [...defaultFilter],
      fields: ["surveyForm", "surveyReferenceId", "surveyTitle", "surveySerialNo", "surveyInvitationStatus", "surveyStatus"],
    };
    const newMode = surveyId ? false : true;
    if (newMode) {
      const existingRecord = sessionStorage.getItem("temp-survey-design");
      const existingData = JSON.parse(existingRecord || {});
      if (!existingRecord || Object.entries(existingData)?.length === 0) return;
      if (JSON.stringify(surveyDesignData) !== existingRecord) {
        formJsonData.surveyForm = existingData;
        setHaveUnsavedChanges(true);
        setSurveyDesignData(existingData);
      }
    }

    await fetchSingleSurvey(surveyId, params)
      .then(async (res) => {
        const data = res.data.data;
        formJsonData = data;
        setDesignData(data);
        if (!data.surveyJson && data.surveyForm && !data.surveyForm.pages.length && data.surveyReferenceId) {
          await fetchSurveys({
            filters: [["_id", "eq", data.surveyReferenceId]],
            fields: ["surveyForm"],
          }).then((r) => {
            const d = r.data.data;
            data.surveyForm = d[0].surveyForm;
            setDesignData(data);
          });
        }
        // CHECK LOCALSTORAGE FOR UNSAVED DATA
        if (!newMode) {
          const existingRecord = sessionStorage.getItem(surveyId);
          const existingData = JSON.parse(existingRecord || {});
          if (!existingRecord || Object.entries(existingData)?.length === 0) return;
          if (JSON.stringify(data.surveyForm) !== existingRecord) {
            formJsonData.surveyForm = existingData;
            setHaveUnsavedChanges(true);
            setSurveyDesignData(existingData);
          }
        }
      })
      .catch((err) => {
        dispatch(actionError(err?.data?.message || ""));
        dispatch(hideLoader());
      });
    return formJsonData;
  };

  useEffect(() => {
    dispatch(setBreadCrumb(breadCrumbLinks));
    dispatch(setSideNavForcedActiveLink("/survey"));
    return () => {
      dispatch(setSideNavForcedActiveLink());
    };
  }, [dispatch, designData, props.selectedIndex]);

  let surveyCreator;
  let handleDesignSave = async () => {
    document.getElementsByClassName("svd_save_btn")[0].click();
    props.handleCommonSave();
  };
  let saveMyForm = async () => {
    let formJsonData = await getFormJsonById();
    const surveyData = surveyCreator?.text || "";
    if (surveyData && JSON.stringify(formJsonData.surveyForm) === JSON.stringify(JSON.parse(surveyData))) {
      let payload = {};
      payload.surveyForm = JSON.parse(surveyData);
      payload.surveyModifiedBy = {
        userId: loggedInUser._id,
        userFname: loggedInUser.user_name,
        userLname: loggedInUser.user_lname,
      };
      await updateSurvey(surveyId, payload)
        .then((res) => {
          if (res) {
            dispatch(hideLoader());
            dispatch(actionSuccess("Survey design has been saved successfully"));
          } else {
            dispatch(hideLoader());
            dispatch(actionError("Unable to process your request. Please contact system administrator (product.support@cheersin.com)"));
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          dispatch(
            actionError(err.data.message || "Unable to process your request. Please contact system administrator (product.support@cheersin.com)")
          );
        });
    } else {
      if (surveyData) {
        let payload = {};
        payload.surveyForm = JSON.parse(surveyData);
        payload.surveyModifiedBy = {
          userId: loggedInUser._id,
          userFname: loggedInUser.user_name,
          userLname: loggedInUser.user_lname,
        };
        dispatch(
          showAlertBox({
            okCallback: async () => {
              await updateSurvey(surveyId, payload)
                .then((res) => {
                  if (res) {
                    dispatch(hideLoader());
                    dispatch(actionSuccess("Survey design has been saved successfully"));
                  } else {
                    dispatch(hideLoader());
                    dispatch(actionError("Unable to process your request. Please contact system administrator (product.support@cheersin.com)"));
                  }
                })
                .catch((err) => {
                  dispatch(hideLoader());
                  dispatch(
                    actionError(
                      err.data.message || "Unable to process your request. Please contact system administrator (product.support@cheersin.com)"
                    )
                  );
                });
            },
            content: "Values altered for few questions in Survey form design, Have you updated the dependent questions?",
          })
        );
      }
    }
  };
  const renderSurveyJS = async () => {
    // SurveyJs locale customization
    let curStrings = SurveyCreator.localization.getLocale("");
    curStrings.ed.designer = "Design Survey";
    curStrings.ed.saveSurvey = "Save Survey";
    curStrings.ed.saveSurveyTooltip = "Save Survey";
    curStrings.ed.testSurvey = "Preview Survey";
    curStrings.ed.testSurveyAgain = "Preview Survey Again";

    // hiding page title and description
    // Survey.Serializer.findProperty("survey", "title").visible = true;
    // Survey.Serializer.findProperty("survey", "description").visible = true;
    // Survey.Serializer.findProperty("page", "title").visible = true;
    // Survey.Serializer.findProperty("page", "description").visible = true;
    // Survey.settings.allowShowEmptyTitleInDesignMode = false;

    // Theme customization
    let defaultThemeColorsSurvey = Survey.StylesManager.ThemeColors["default"];
    defaultThemeColorsSurvey["$main-color"] = mainColor;
    defaultThemeColorsSurvey["$main-hover-color"] = mainHoverColor;
    defaultThemeColorsSurvey["$text-color"] = textColor;
    defaultThemeColorsSurvey["$header-color"] = headerColor;
    defaultThemeColorsSurvey["$header-background-color"] = headerBackgroundColor;
    defaultThemeColorsSurvey["$body-container-background-color"] = bodyContainerBackgroundColor;

    let defaultThemeColorsEditor = SurveyCreator.StylesManager.ThemeColors["default"];
    defaultThemeColorsEditor["$primary-color"] = mainColor;
    defaultThemeColorsEditor["$secondary-color"] = mainColor;
    defaultThemeColorsEditor["$primary-hover-color"] = mainHoverColor;
    defaultThemeColorsEditor["$primary-text-color"] = textColor;
    defaultThemeColorsEditor["$selection-border-color"] = mainColor;

    SurveyCreator.StylesManager.applyTheme("default");

    //Start - CKEditor Integration

    const CkEditor_ModalEditor = {
      afterRender: function (modalEditor, htmlElement) {
        if (typeof CKEDITOR === "undefined") return;

        var editor =
          CKEDITOR &&
          CKEDITOR.replace(htmlElement, {
            toolbarGroups: [
              { name: "clipboard", groups: ["clipboard", "undo"] },
              {
                name: "editing",
                groups: ["find", "selection", "spellchecker", "editing"],
              },
              { name: "links", groups: ["links"] },
              { name: "insert", groups: ["insert"] },
              { name: "forms", groups: ["forms"] },
              { name: "tools", groups: ["tools"] },
              { name: "document", groups: ["mode", "document", "doctools"] },
              { name: "others", groups: ["others"] },
              { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
              {
                name: "paragraph",
                groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
              },
              { name: "styles", groups: ["styles"] },
              { name: "colors", groups: ["colors"] },
              { name: "about", groups: ["about"] },
            ],
            removeButtons: "Subscript,Superscript,PasteText,PasteFromWord,Scayt,Strike,RemoveFormat,Blockquote,About",
          });

        var isUpdating = false;
        editor.on("change", function () {
          isUpdating = true;
          modalEditor.editingValue = editor.getData();
          isUpdating = false;
        });
        editor.setData(modalEditor.editingValue);
        modalEditor.onValueUpdated = function (newValue) {
          if (!isUpdating) {
            editor.setData(newValue);
          }
        };
      },
      destroy: function (modalEditor, htmlElement) {
        if (typeof CKEDITOR === "undefined") return;
        var instance = CKEDITOR.instances[htmlElement.id];
        if (instance) {
          instance.removeAllListeners();
          instance.destroy(true);
          CKEDITOR.remove(instance);
        }
      },
    };
    SurveyCreator.SurveyPropertyModalEditor.registerCustomWidget("html", CkEditor_ModalEditor);
    SurveyCreator.SurveyPropertyModalEditor.registerCustomWidget("text", CkEditor_ModalEditor);

    //Create showdown markdown converter
    var converter = new showdown.Converter();
    function doMarkdown(survey, options2) {
      //convert the markdown text to html
      var str = converter.makeHtml(options2.text);
      if (str.indexOf("<p>") == 0) {
        //remove root paragraphs<p></p>
        str = str.substring(3);
        str = str.substring(0, str.length - 4);
      }
      //set html
      options2.html = str;
    }
    //End - CKEditor Integration

    if (productVariant === PRODUCT_VARIANT.WHATNEXT) {
      Survey.Serializer.addProperty("question", {
        title: "Custom Title",
        displayName: "Custom Title",
        name: "customTitle",
        type: "text",
        category: "general",
        visibleIndex: 2,
      });
      Survey.Serializer.addProperty("question", {
        title: "Chart Visualization Type",
        displayName: "Chart Visualization Type",
        name: "chartVisualizationType",
        type: "dropdown",
        choices: ["Bar Chart", "Line Chart", "World Map"],
        default: "Bar Chart",
        category: "general",
        visibleIndex: 7,
      });
      Survey.Serializer.addProperty("question", {
        title: "Hide Question for Clients",
        displayName: "Hide Question for Clients",
        name: "hideQuestionToClient",
        type: "switch",
        category: "general",
        visibleIndex: 5,
      });
      Survey.Serializer.addProperty("question", {
        name: "Weightage:number",
        category: "general",
        max: 10,
      });
    }

    let options = {
      showLogicTab: false,
      showEmbededSurveyTab: false,
      showJSONEditorTab: false,
      pageEditMode: "multiple",
      allowControlSurveyTitleVisibility: true,
      haveCommercialLicense: true,
      // showTestSurveyTab:false,
      // showSurveyTitle: "never"
    };

    surveyCreator = new SurveyCreator.SurveyCreator(
      "surveyCreatorContainer",
      // "creatorElement",
      options
    );

    surveyCreator.showToolbox = "right";
    surveyCreator.showPropertyGrid = "right";
    surveyCreator.rightContainerActiveItem("toolbox");

    // Set custom designer placeholder
    surveyCreator.placeholderHtml =
      '<div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center;"><div style="font-size: 16px; max-width: 210px;">' +
      "Drag and drop a question to start designing your survey" +
      "</div>" +
      "</div>";

    //Start - CKEditor Integration
    surveyCreator.survey.onTextMarkdown.add(doMarkdown);
    surveyCreator.onDesignerSurveyCreated.add(function (editor, options1) {
      options1.survey.onTextMarkdown.add(doMarkdown);
    });
    surveyCreator.onTestSurveyCreated.add(function (editor, options1) {
      options1.survey.onTextMarkdown.add(doMarkdown);
    });
    surveyCreator.onPropertyValidationCustomError.add(function (editor, opt) {
      if (opt.propertyName != "Weightage") return;
      if (opt.value > 100) {
        opt.error = "Please enter number less than 100";
      }
    });
    surveyCreator.onPropertyValidationCustomError.add(function (editor, opt) {
      if (opt.obj.ownerPropertyName != "rows") return;
      if (opt.value.includes(".") || opt.value.includes("$")) {
        opt.error = "$ and . not allowed";
      }
    });

    //End - CKEditor Integration

    //Grouping the items on the toolbox
    surveyCreator.toolbox.changeCategories([
      {
        name: "panel",
        category: "Panels",
      },
      {
        name: "paneldynamic",
        category: "Panels",
      },
      {
        name: "matrix",
        category: "Matrix",
      },
      {
        name: "matrixdropdown",
        category: "Matrix",
      },
      {
        name: "matrixdynamic",
        category: "Matrix",
      },
    ]);

    let surveySettingsAction = surveyCreator.toolbarItems().filter((item) => {
      return item.id === "svd-survey-settings";
    })[0];
    surveyCreator.toolbarItems.remove(surveySettingsAction);

    let data = await getFormJsonById();
    if (!data?.surveyForm) {
      data.surveyForm = {
        pages: [],
        showPreviewBeforeComplete: "showAllQuestions",
      };
    }
    surveyCreator.onModified.add((options1) => {
      setSurveyDesignData(JSON.parse(options1.text));
    });

    if (Object.keys(surveyDesignData).length !== 0) {
      surveyCreator.JSON = surveyDesignData;
    } else {
      setSurveyDesignData(data.surveyForm);
      surveyCreator.JSON = data?.surveyForm;
    }
    let saveBtn = document.getElementsByClassName("svd_save_btn");
    if (saveBtn.length > 0) document.getElementsByClassName("svd_save_btn")[0].style.display = "none";
    if (shouldRefetch) {
      dispatch(setDesign(data.surveyForm));
      surveyCreator.JSON = data?.surveyForm;
    }
    setShouldRefetch(false);
    dispatch(hideLoader());
  };
  useEffect(() => {
    const debounced = debounce(writeUnsavedChanges, 1000, { maxWait: 5000 });
    debounced();
    dispatch(setDesign(surveyDesignData));
  }, [surveyDesignData]);

  useEffect(() => {
    dispatch(showLoader());
    renderSurveyJS();
  }, []);
  useEffect(() => {
    if (shouldRefetch) {
      renderSurveyJS();
    }
  }, [shouldRefetch]);

  const writeUnsavedChanges = () => {
    try {
      if (!surveyDesignData || Object.entries(surveyDesignData)?.length === 0) return;
      const newMode = surveyId ? false : true;
      if (newMode) return sessionStorage.setItem("temp-survey-design", JSON.stringify(surveyDesignData));
      return sessionStorage.setItem(surveyId, JSON.stringify(surveyDesignData));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {props?.interfaceAccess ? (
        <div>
          <div className="gennx-content-wrapper content-wrapper pt-3 mx-2 padding-bottom-100i footer-button-wrapper">
            <div id="surveyCreatorContainer" style={{ width: "99.5%" }} className="pb-4" />
          </div>
          <ActionButtons
            accessRights={props.accessRights}
            hasUnsavedChanges={haveUnsavedChanges}
            setHasUnsavedChanges={setHaveUnsavedChanges}
            setShouldRefetch={setShouldRefetch}
            selectedIndex={props.selectedIndex}
            setCurrentTab={(selectedIndex) => {
              props.setCurrentTab(selectedIndex);
            }}
            surveyId={surveyId}
            handleSave={
              designData?.surveyInvitationStatus != SURVEY_CONSTANT.SURVEY_INVITATION_STATUS.SENDING &&
              !["Open", "Closed"].includes(designData?.surveyStatus)
                ? handleDesignSave
                : null
            }
            tabsName={props.tabsName}
            moduleName={props.moduleName}
            hashArr={props.hashArr}
          />
        </div>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default DesignSurvey;
