/**
 * @author: Amrutesh Devadas | Cheers Interactive
 * @date : 31/Dec/2021
 * File Description: Export JSON to Excel
 */

import * as Survey from "survey-react";
const { generateExcel } = require("json5-to-table");

/**
 * Represents a book.
 * @param {string} author - The short name of the survey.
 */

function ExportAnalytics(data, shortName, isDraft) {
  /**
   * Generates Downloadable Excel File
   * @param {object} data - Survey Data .
   * @param {String}  shortName - Survey short name
   */
  let exportData = JSON.stringify(data.surveyForm).replace(/<\/?[^>]+>/gi, "");
  const survey = new Survey.Model(JSON.parse(exportData)); //urveyData.surveyForm

  //returns schema object for the file

  function getQuestionList() {
    let titleArray = [];
    titleArray = survey.getAllQuestions().map(function (question) {
      let schemaObject = {};
      schemaObject["title"] = question.title
        .replace(/<[^>]*(>|)|\n|&nbsp;/gi, " ")
        .replace(/&#39;/g, "'")
        .replace(/&amp;/g, "&");
      schemaObject["path"] = question.name;

      // schema[props] for matrix type of question

      if (question.getType() === "matrix") {
        let matrixRows = question.getPropertyValue("rows");
        let matrixPropsObject = matrixRows.map((rowValues) => {
          let rowValuesObject = {};
          rowValuesObject["title"] = rowValues.value;
          rowValuesObject["path"] = rowValues.value;
          return rowValuesObject;
        });
        if (matrixPropsObject?.length > 0) schemaObject["props"] = matrixPropsObject;
      }

      // schema[props] for multipletext type of question
      else if (question.getType() === "multipletext") {
        let multipletextValues = question.getPropertyValue("items");
        let multipleTextPropsObject = multipletextValues.map((textValues) => {
          let textValuesObject = {};
          textValuesObject["title"] = textValues.name;
          textValuesObject["path"] = textValues.name;
          return textValuesObject;
        });
        if (multipleTextPropsObject?.length > 0) schemaObject["props"] = multipleTextPropsObject;
      }

      // Handle datepicker type of question
      else if (question.getType() === "datepicker") {
        schemaObject["title"] = question.title;
        schemaObject["path"] = question.name;
      }

      return schemaObject;
    });

    return titleArray;
  }

  // function call to generate excel file

  let fileName = shortName;
  const date = new Date();

  if (isDraft) fileName = fileName + "_draft";

  generateExcel(data.surveyResponse, getQuestionList(), {
    writeTo: `${date.toISOString().split("T")[0].replace(/-/g, "").trim()}${fileName}.xlsx`,
  });
}
export default ExportAnalytics;
